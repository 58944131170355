
global.jQuery = require('jquery'); 
const bootstrap = require('bootstrap');
let owlCarousel = require('owl.carousel')
window.fn = owlCarousel;


jQuery('.owl-carousel').owlCarousel({
    loop:true,
    margin:0,
    nav:true,
    items:1,
    navText: ['<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" /> </svg>','<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"> <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" /></svg>']
})

$('.wrapper-item-menu').click(function(){
    $('#nav-icon2').toggleClass('open');
    $('.panel-menu').toggleClass('active');
});

var smooth = [$('a.smooth'), 100, 750];

smooth[0].click(function() {
   $('html, body').animate({
      scrollTop: $('[id="' + $.attr(this, 'href').substr(1) + '"]').offset().top -smooth[1]
   }, smooth[2]);
   $('#nav-icon2').removeClass('open');
   $('.panel-menu').removeClass('active');
   return false;
   
});
$(window).on('hashchange', function(e){
    history.replaceState ("", document.title, e.originalEvent.oldURL);
});

